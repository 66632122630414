import { getUserData } from "@/auth/utils";
export default {
  themeType: "light",
  userData: getUserData(),
  avatarNameGenerator: (f, l) => {
    if (f) {
      return `${f.charAt(0)}%20${l.charAt(0)}`;
    } else {
      return `S%20G`;
    }
  },
  userPrioritiesObj: {},
  reportUrl: "",
  userCharacter: {},
  isTalentReportGenerated: false,
  orgId: "",
  selectedPersonalities: [],
  resetUserAbility: false,
  appSubscriptions: {},

};
