const constants = {
  clientId: "c73115a0-32b2-40fa-8d07-aa9bef09293c",
  url: process.env.VUE_APP_url,
  s3Bucket: process.env.VUE_APP_s3Bucket,
  loginServiceUrl: process.env.VUE_APP_loginServiceUrl,
  gatewayServiceUrl: process.env.VUE_APP_gatewayServiceUrl,
  editorUrl: process.env.VUE_APP_editorUrl,
  journeyApiUrl: process.env.VUE_APP_baseApiUrl + '/journey/integration',
  baseApiUrl: process.env.VUE_APP_baseApiUrl,
};

export default constants;
