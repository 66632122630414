import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/oauth-callback",
      name: "oauth-callback",
      component: () => import("@/auth/OAuthCallback.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/feedback",
      name: "Feedback",
      component: () => import("@/views/feedback/Main.vue"),
      meta: {
        layout: "full",
        pageTitle: "",
        breadcrumb: [
          {
            text: "",
            active: true,
          },
        ],
      },
      beforeEnter(to, from, next) {
        // Proceed with the route update
        if (!to.query.token || !to.query.sender) {
          next({
            name: "error-404",
          });
        } else {
          next();
        }
      },
    },
    {
      path: "/feedback-given",
      name: "Already Given Feedback",
      component: () => import("@/views/feedback/AlreadyGivenFeedback.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/thank-you",
      name: "Thank You",
      component: () => import("@/views/Thanks.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/:orgId",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
        layout: "full",
      },
    },
   
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
