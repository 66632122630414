export default {
  SET_USER(state, value) {
    state.data = value;
  },
  SET_USER_ACTIVE_ORG_ID(state, value) {
    state.orgId = value;
  },
  SET_USER_CHARACTER(state, value) {
    state.userCharacter = value;
  },
  SET_IS_USER_ABILITIES(state, value) {
    state.isAbilitiesPreset = value;
  },
  SET_USER_PRIORITIES_OBJ(state, value) {
    state.userPrioritiesObj = value;
  },
  SET_REPORT_URL(state, value) {
    state.reportUrl = value;
  },
  IS_TALENT_REPORT_GENERATED(state, value) {
    state.isTalentReportGenerated = value;
  },

  SET_SELECTED_PERSONALITIES(state, value) {
    state.selectedPersonalities = value;
  },
  RESET_USER_ABILITIES(state, value) {
    state.resetUserAbility = value;
  },
  SET_USER_APP_SUBSCRIPTIONS(state, value) {
    state.appSubscriptions = value;
  },
};
