export default {
  OPEN_EDITOR_POPUP(state, value) {
    state.editorPopup = value;
  },
  OPEN_CHAT_BOT_POPUP(state, value) {
    state.chatBotPopup = value;
  },
  OPEN_REPORT_POPUP(state, value) {
    state.talentReportPopup = value;
  },
  SET_UNIVERSAL_LOADING(state, value) {
    state.isUniversalLoading = value;
  },
};
