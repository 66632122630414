import axios from "@/libs/axios";
import constants from "@/constants";
import state from "./moduleUserState";

export default {
  async fetchUserDetailsByToken(_, token) {
    const res = await axios.get(`${constants.gatewayServiceUrl}/user/${token}`);
    return res.data;
  },
  async getUserAbility(_, payload) {
    const res = await axios.get(
      `${constants.journeyApiUrl}/ability/${state.orgId}/${payload}`
    );
    return res.data.data;
  },
  async saveUserAbility(_, payload) {
    const saveAbility = await axios.post(`${constants.journeyApiUrl}/ability`, {
      gtbrddUserId: payload.userId,
      gtbrddOrganizationId: state.orgId,
      abilities: payload.abilities,
    });
    return saveAbility.data;
  },
  async saveUserPriority(_, payload) {
    const saveAbility = await axios.post(
      `${constants.journeyApiUrl}/ability/priority`,
      {
        gtbrddUserId: payload.userId,
        gtbrddOrganizationId: state.orgId,
        priorities: payload.priorities,
      }
    );
    return saveAbility;
  },
  async getGuardian(_, payload) {
    const guardians = await axios.get(
      `${constants.journeyApiUrl}/advisor/${state.orgId}/${payload._id}`
    );
    return guardians.data;
  },
  async addGuardian(_, payload) {
    const addGuardian = await axios.post(`${constants.journeyApiUrl}/advisor`, {
      ...payload,
      gtbrddOrganizationId: state.orgId,
    });
    return addGuardian.data.data;
  },
  async reSendGuardianInvite(_, payload) {
    const res = await axios.post(
      `${constants.journeyApiUrl}/advisor/resend-invite/${payload}`
    );
    return res.data;
  },
  async getStrengthProfile(_, payload) {
    const res = await axios.get(
      `${constants.journeyApiUrl}/strength-profile/${state.orgId}/${payload}`
    );
    return res.data;
  },
  async getSituationsReport(_, payload) {
    const res = await axios.get(
      `${constants.journeyApiUrl}/situations/${state.orgId}/${payload}`
    );
    return res.data;
  },
  async getPlanReport(_, payload) {
    const res = await axios.get(
      `${constants.journeyApiUrl}/planner/${state.orgId}/${payload}`
    );
    return res.data;
  },
  async fetchUserAppSubscriptions({ commit }, payload) {
    const resObj = await axios.get(
      `${constants.baseApiUrl}/user/app-subscriptions/${payload}`
    );
    commit("SET_USER_APP_SUBSCRIPTIONS", resObj.data.data);
    return resObj.data.data;
  },
  //feedback
  async getFeedbackDetails(_, token) {
    const res = await axios.get(
      `${constants.journeyApiUrl}/advisors?token=${token}`
    );
    return res.data;
  },
  async addEndorsement(_, payload) {
    const res = await axios.post(`${constants.journeyApiUrl}/endorsement`, {
      token: payload.userId,
      assessmentAnswers: payload.abilities,
      gtbrddOrganizationId: constants.orgId,
    });

    return res.data.data;
  },
};
